/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { ColumnCover, Column, Menu, ColumnWrapper, Title, MenuWrap, MenuButton, MenuWrapBox, Button, MenuButtonClose, ColumnWrap, Subtitle, Text, Image, PriceWrap, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <ColumnCover name={"menu"}>
          
          <Column className="--menu --style4 pb--40 pt--60" menu={true}>
            
            <Menu className="flex--center">
              
              <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":500}}>
                
                <Title className="title-box fs--36 title-box--invert" content={"<span style=\"font-weight: bold; text-decoration-line: underline;\">Autoservis BSJ s.r.o</span>"}>
                </Title>

              </ColumnWrapper>

              <MenuWrap >
                
                <MenuButton >
                </MenuButton>

                <MenuWrapBox >
                  
                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--cColor1 btn-box--invert pr--20" href={"#vymena-pneu"} style={{"maxWidth":347}} tsbtn={null} target={""} content={"Výměna pneu i v SOBOTU/ NEDĚLI"}>
                  </Button>

                  <MenuButtonClose >
                  </MenuButtonClose>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--invert pr--20" href={"#cenik"} target={""} content={"Ceník služeb"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--invert pr--20" href={"#reference"} target={""} content={"email: bsj-servis@seznam.cz"}>
                  </Button>

                  <Button className="btn-box btn-box--hvr3 btn-box--cbtn1 btn-box--sbtn2 btn-box--invert pr--20" target={""} content={"&nbsp;+420 731&nbsp; 489&nbsp; 315,<div>&nbsp;+420 776 099&nbsp; 366</div>"}>
                  </Button>

                </MenuWrapBox>

              </MenuWrap>

            </Menu>

          </Column>

        </ColumnCover>


        <Column className="css-cz2m0b --full pb--60 pt--60" name={"hlavicka"} style={{"backgroundColor":"rgba(0,0,0,1)"}} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8081/6e6bebeecdce4202a91ee1328acc8205_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8081/6e6bebeecdce4202a91ee1328acc8205_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8081/6e6bebeecdce4202a91ee1328acc8205_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8081/6e6bebeecdce4202a91ee1328acc8205_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8081/6e6bebeecdce4202a91ee1328acc8205_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8081/6e6bebeecdce4202a91ee1328acc8205_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8081/6e6bebeecdce4202a91ee1328acc8205_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8081/6e6bebeecdce4202a91ee1328acc8205_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--1 flex--bottom" columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":1080}}>
              
              <Subtitle className="subtitle-box fs--36 subtitle-box--invert" content={"<span style=\"font-weight: bold;\">Jeremiášova 947/16, PRAHA Stodůlky, 155 00<br>OD 15.8.2022<span style=\"color: rgb(246, 57, 57);\"> </span><span style=\"color: rgb(226, 8, 8);\">NOVĚ V BEROUNĚ</span> U PRAHY!<br></span><span style=\"font-weight: bold;\">NA MÁCHOVNĚ 1270, BEROUN</span><br>"}>
              </Subtitle>

              <Title className="title-box fs--48 title-box--invert lh--12 mt--02" content={"Autoservis, na který se můžete spolehnout i o <span style=\"text-decoration-line: underline; color: rgb(239, 0, 0);\">víkendu</span>!&nbsp;<div>Opravujeme osobní, užitková i nákladní vozidla.</div><div><span style=\"color: rgb(254, 0, 0);\">PŮJČENÍ DODÁVEK 500kč/den</span></div>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--center pb--20 pt--60" name={"vymena-pneu"} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" style={{"maxWidth":500}} content={"Výměna\r\npneumatik"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":500}} content={"U nás máte možnost přezutí i o víkendech, díky špičkovému vybavení umíme přezout jakákoliv kola osobních vozidel včetně užitkových do velikosti kol 24 palců. Pneumatiky přezouváme na počkání."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="mb--0" alt={""} src={"https://cdn.swbpg.com/t/8081/76f609c6b6c54a2a83dbaecdf97aa77f_s=660x_.jpg"} svg={false} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/8081/76f609c6b6c54a2a83dbaecdf97aa77f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8081/76f609c6b6c54a2a83dbaecdf97aa77f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8081/76f609c6b6c54a2a83dbaecdf97aa77f_s=860x_.jpg 860w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="bg--center pb--60" name={"pneu-sluzby"} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--3 pb--20 pt--20" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":407}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/8081/44f63c09197a42beac1615ead90b88e0_s=660x_.jpg"} svg={false} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/7580/44f63c09197a42beac1615ead90b88e0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8081/44f63c09197a42beac1615ead90b88e0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8081/44f63c09197a42beac1615ead90b88e0_s=860x_.jpg 860w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--30" content={"<span style=\"text-decoration-line: underline;\">Diagnostika, plnění klimatizací</span>"}>
              </Subtitle>

              <Text className="text-box" content={"Používáme kvalitní vybavení diagnostiky pro osobní i Truck/ nákladní vozidla od firmy BOSCH, plníme klimatizace pomocí vysoce kvalitního přístroje od firmy TEXA."}>
              </Text>

              <Subtitle className="subtitle-box" content={""}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":407}}>
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/8523/24145201b0624a34b0470a25179d55b3_s=860x_.jpg"} svg={false} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/8523/24145201b0624a34b0470a25179d55b3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8523/24145201b0624a34b0470a25179d55b3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8523/24145201b0624a34b0470a25179d55b3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8523/24145201b0624a34b0470a25179d55b3_s=1400x_.jpg 1400w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--30" content={"<span style=\"text-decoration-line: underline;\">Autoservis</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":391}} content={"Nabízíme širokou nabídku služeb:&nbsp;<br>Motorové práce, karosářské práce, výměna brzd, oprava nákladních vozidel, podvozkové, elektrikářské práce a jiné!<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Neváhejte zavolat!"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/8081/0314f83f927241288c5f23913d56e419_s=860x_.jpg"} svg={false} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/8081/0314f83f927241288c5f23913d56e419_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8081/0314f83f927241288c5f23913d56e419_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8081/0314f83f927241288c5f23913d56e419_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8081/0314f83f927241288c5f23913d56e419_s=1400x_.jpg 1400w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box mt--30" content={"<span style=\"text-decoration-line: underline;\">Pneuservis</span>"}>
              </Subtitle>

              <Text className="text-box fs--16" content={"Nabízíme kvalitní pneuservis pro osobní a užitková vozidla do velikosti kol 24 palců, jsme schopní po dohodě zajistit i přezutí nákladních automobilů včetně zajištění pneumatik."}>
              </Text>

              <Subtitle className="subtitle-box" content={""}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-evs3yy pb--80 pt--80" name={"reference"} style={{"backgroundColor":"rgba(0,0,0,1)"}} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8523/994752db1bee4ddbbbb5c1c464c65f8d__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8523/994752db1bee4ddbbbb5c1c464c65f8d__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8523/994752db1bee4ddbbbb5c1c464c65f8d__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8523/994752db1bee4ddbbbb5c1c464c65f8d__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8523/994752db1bee4ddbbbb5c1c464c65f8d__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8523/994752db1bee4ddbbbb5c1c464c65f8d__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8523/994752db1bee4ddbbbb5c1c464c65f8d__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0.65)), url(https://cdn.swbpg.com/t/8523/994752db1bee4ddbbbb5c1c464c65f8d__s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--20 pt--20" columns={"1"}>
            
            <ColumnWrapper className="pb--20">
              
              <Title className="title-box title-box--invert pr--10" content={"\"Nejdéle otevřený Autoservis U PRAHY\"\n"}>
              </Title>

              <Text className="text-box fs--30 w--400 text-box--invert" content={"<span style=\"font-weight: bold;\">OPRAVUJEME V SOBOTU I V NEDĚLI OD 8:00 DO 20:00 PO DOHODĚ I DÉLE.</span>"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--cColor2 btn-box--filling2" style={{"backgroundColor":"rgba(255,255,255,1)"}} content={"<span style=\"color: rgb(206, 0, 0);\">NA MÁCHOVNĚ 1270, BEROUN</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1kjqefg mb--20 pb--30 pt--80" name={"cenik"} style={{"backgroundColor":"rgba(255,255,255,1)"}} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/8081/13166d6b22fa47659b0eec05995aedaf_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/8081/13166d6b22fa47659b0eec05995aedaf_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/8081/13166d6b22fa47659b0eec05995aedaf_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/8081/13166d6b22fa47659b0eec05995aedaf_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/8081/13166d6b22fa47659b0eec05995aedaf_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/8081/13166d6b22fa47659b0eec05995aedaf_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/8081/13166d6b22fa47659b0eec05995aedaf_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/8081/13166d6b22fa47659b0eec05995aedaf_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Základní ceník<br>\r\nservisních služeb"}>
              </Title>

              <Text className="text-box" content={"Ceny jsou orientační."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--60" name={"polozky"} style={{"backgroundColor":"rgba(255,255,255,1)"}}>
          
          <ColumnWrap className="column__flex el--1 flex--top" columns={"1"}>
            
            <ColumnWrapper className="mt--0 pb--0 pt--0">
              
              <PriceWrap className="mb--02 mt--02 pb--08 pl--20 pr--20 pt--08 flex--center" style={{"backgroundColor":"rgba(246, 248, 251, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Subtitle className="subtitle-box fs--18" content={"Výměna oleje&nbsp;&nbsp;"}>
                  </Subtitle>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box fs--18" content={"od 400 Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--02 mt--02 pb--08 pl--20 pr--20 pt--08 flex--center" style={{"backgroundColor":"rgba(246, 248, 251, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Subtitle className="subtitle-box fs--18" content={"Výměna a servis brzd&nbsp;&nbsp;"}>
                  </Subtitle>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box fs--18" content={"od 400 Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--02 mt--02 pb--08 pl--20 pr--20 pt--08 flex--center" style={{"backgroundColor":"rgba(246, 248, 251, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Subtitle className="subtitle-box fs--18" content={"Karosářské práce&nbsp;"}>
                  </Subtitle>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box fs--18" content={"od 400 Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--02 mt--02 pb--08 pl--20 pr--20 pt--08 flex--center" style={{"backgroundColor":"rgba(246, 248, 251, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Subtitle className="subtitle-box fs--18" content={"Výměna a servis rozvodů / spojek"}>
                  </Subtitle>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box fs--18" content={"od 3000 Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--02 mt--02 pb--08 pl--20 pr--20 pt--08 flex--center" style={{"backgroundColor":"rgba(246, 248, 251, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Subtitle className="subtitle-box fs--18" content={"Výměna pneu&nbsp;"}>
                  </Subtitle>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box fs--18" content={"od 150 Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="mb--02 mt--02 pb--08 pl--20 pr--20 pt--08 flex--center" style={{"backgroundColor":"rgba(246, 248, 251, 1)"}}>
                
                <ColumnWrapper >
                  
                  <Subtitle className="subtitle-box fs--18" content={"Diagnostika osobní/užitková/nákladní"}>
                  </Subtitle>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box fs--18" content={"600/800/1000 Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mt--0 pb--80" name={"vyhody"} layout={"l1"}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="mb--25" alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/Auto-Moto/auto-moto-16.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":130}} srcSet={""} content={null} svgColor={"rgba(31, 34, 40, 1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Kontrola zbytku vozu při opravě pro klienty zdarma."}>
              </Subtitle>

              <Text className="text-box" content={"Pokud u nás provádíme opravu, nabízíme našim klientům zdarma prověření zbytku vozu."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="mb--25" alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/Auto-Moto/auto-moto-1.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":130}} srcSet={""} content={null} svgColor={"rgba(31, 34, 40, 1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Kvalitní dodavatel náhradních dílů."}>
              </Subtitle>

              <Text className="text-box" content={"Odebíráme pouze kvalitní náhradní díly od prověřených dodavatelů."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="mb--25" alt={""} src={"https://cdn.swbpg.com/o/g/Ikonky/Auto-Moto/auto-moto-15.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":130}} srcSet={""} content={null} svgColor={"rgba(31, 34, 40, 1)"}>
              </Image>

              <Subtitle className="subtitle-box" content={"Příprava na STK"}>
              </Subtitle>

              <Text className="text-box" content={"Provádíme přípravu pro STK/kontrola vozu."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fotky"} layout={"l12"}>
          
          <ColumnWrap className="column__flex el--4 --full" columns={"4"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/8081/0314f83f927241288c5f23913d56e419_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/8081/0314f83f927241288c5f23913d56e419_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8081/0314f83f927241288c5f23913d56e419_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8081/0314f83f927241288c5f23913d56e419_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8081/0314f83f927241288c5f23913d56e419_s=1400x_.jpg 1400w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/8081/e342764ef48e484ba1caca9e370c1185_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/8081/e342764ef48e484ba1caca9e370c1185_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8081/e342764ef48e484ba1caca9e370c1185_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8081/e342764ef48e484ba1caca9e370c1185_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8081/e342764ef48e484ba1caca9e370c1185_s=1400x_.jpg 1400w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/8081/44f63c09197a42beac1615ead90b88e0_s=660x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/8081/0314f83f927241288c5f23913d56e419_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/8081/0314f83f927241288c5f23913d56e419_s=350x_.jpg 350w, https://cdn.swbpg.com/t/8081/0314f83f927241288c5f23913d56e419_s=660x_.jpg 660w, https://cdn.swbpg.com/t/8081/0314f83f927241288c5f23913d56e419_s=860x_.jpg 860w, https://cdn.swbpg.com/t/8081/0314f83f927241288c5f23913d56e419_s=1400x_.jpg 1400w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--60" name={"kontakt"}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Autoservis BSJ s.r.o"}>
              </Subtitle>

              <Text className="text-box" content={"<div>Na Máchovně 1270</div><div>Beroun, Praha - západ, 266 01<br>Česká republika</div>"}>
              </Text>

              <Subtitle className="subtitle-box fs--13 w--400 lh--12 mt--16" style={{"maxWidth":300}} content={"(Areál vjezdem rovně kolem žluté benzínky, fialová hala)<br>Obchodní podmínky jsou k dispozici v sídle firmy.<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Kontakt:"}>
              </Subtitle>

              <Text className="text-box" content={"E-mail:"}>
              </Text>

              <Subtitle className="subtitle-box" content={"bsj-servis@seznam.cz"}>
              </Subtitle>

              <Text className="text-box" content={"Telefonní kontakt:"}>
              </Text>

              <Subtitle className="subtitle-box" content={"+420 731 489 315,&nbsp;<div>+420 776 099 366</div>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Subtitle className="subtitle-box" content={"Otevřeno"}>
              </Subtitle>

              <Text className="text-box" content={"PONDĚLÍ - PÁTEK"}>
              </Text>

              <Subtitle className="subtitle-box" content={"8:00 — 20:00"}>
              </Subtitle>

              <Text className="text-box" content={"SOBOTA - NEDĚLE"}>
              </Text>

              <Subtitle className="subtitle-box" content={"8:00 — 20:00"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style3 mt--0" name={"mapa"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"17"} place={"Na Máchovně 1270"} style={{"marginBottom":14}}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>

      </Layout>
    </ThemeWrapper>
  )
}